import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded"
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded"
import { Collapse, IconButton, List, ListItem, ListItemText, Theme } from "@mui/material"
import { createStyles, makeStyles } from "@mui/styles"
import {
  AppDrawer,
  APP_NAME,
  authenticationContext,
  AUTH_DATA,
  axiosInstance,
  CfgUnitaProduttiveAccesso,
  ContentContainer,
  FallbackComponent,
  getDecodedToken,
  getEnvironmentString,
  getLoginAccesso,
  GET_CFG_UNITA_PRODUTTIVE_ACCESSO,
  GET_LOCALIZZAZIONI_DATI_ALL,
  GET_ZIC_CFG_SISTEMA_ACCESSO,
  graphqlClient,
  LanguageKey,
  LocalizzazioniDatiAll,
  LoginResponsePayload,
  StatusBar,
  Topbar,
  useAppCommonStyles,
  useQuery,
  useStore,
  X_TERYA_IDSISTEMA,
  ZicCfgSistemiAccesso
} from "@terya/tsuite-common"
import { useTranslate } from "@tolgee/react"
import clsx from "clsx"
import * as A from "fp-ts/Array"
import React, { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import packageJson from "../../package.json"

const useSkeletonStyles = makeStyles((theme: Theme) =>
  createStyles({
    navigationMenuItem: {
      borderRadius: 4,
      margin: "1px 0",
      paddingRight: "12px!important"
    },
    navigationMenuNestedItem: {
      borderRadius: 4,
      margin: "1px 0",
      paddingLeft: 30
    },
    activeNavigationMenuItem: {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
      "&::before": {
        transition: ".4s",
        content: "''",
        width: 4,
        height: 24,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 4,
        position: "absolute",
        left: 4,
        top: "50%",
        transform: "translateY(-50%)"
      }
    },
    activeNavigationMenuNestedItem: {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
      "&::before": {
        transition: ".4s",
        content: "''",
        width: 4,
        height: 24,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 4,
        position: "absolute",
        left: 16,
        top: "50%",
        transform: "translateY(-50%)"
      }
    }
  })
)

// LogicRoute
type LR<R extends string> = `/${R}`
type Routes =
  | LR<"dashboard">
  | LR<"report">
  | LR<"report/classi">
  | LR<"report/nuovo">
  | LR<"report/modifica">
  | LR<"utenti">
  | LR<"utenti-ruoli">
  | LR<"utenti-lista">
  | LR<"operazioni-programmate">

interface Modulo {
  modulo: string
  route: Routes
  headerOnly: boolean
  enabled: boolean
  children: Modulo[]
  parent?: Routes
}

function mkModulo(modulo: string, route: Routes, headerOnly: boolean, enabled: boolean, children: Modulo[], parent?: Routes): Modulo {
  return { modulo, route, headerOnly, enabled, parent, children }
}

const moduli: Modulo[] = [mkModulo("Dashboard", "/dashboard", false, true, []), mkModulo("Operazioni programmate", "/operazioni-programmate", false, true, [])]

const Skeleton: React.FC = props => {
  const styles = useSkeletonStyles()
  const commonStyles = useAppCommonStyles()
  const t = useTranslate()
  const location = useLocation()
  const history = useHistory()
  const [loadingAuthData, setLoadingAuthData] = useState(false)

  const [collapseState, setCollapseState] = useState<{ [route: string]: boolean }>({})

  const [activeRoute, setActiveRoute] = useState<Routes>("/dashboard")

  const authContext = authenticationContext()
  const languageSlice = useStore(state => ({
    addLanguageKey: state.addLanguageKey,
    selectLanguageKey: state.selectLanguageKey
  }))
  const changeRoute = (modulo: Modulo) => {
    setActiveRoute(modulo.route as Routes)
    history.push(modulo.route)
  }

  function mergeModuli(_moduli: Modulo[]): Modulo[] {
    return A.flatten(_moduli.map(m => [m, ...mergeModuli(m.children ?? [])]))
  }

  useEffect(() => {
    const route = location.pathname.split("/")[1]
    const mergedModuli = mergeModuli(moduli)
    const modulo = mergedModuli.find(m => m.route === `/${route}`)

    if (modulo) {
      setActiveRoute(modulo.route)
      if (modulo.parent) setCollapseState({ [modulo.parent]: true })
    }
  }, [location])

  function onClickLogout() {
    authContext.clearData()
    localStorage.removeItem(`${APP_NAME}_${AUTH_DATA}`)

    if (window.location.hostname === "localhost") {
      return history.push("/")
    } else window.location.href = `https://login${getEnvironmentString()}.tsuite.cloud`
  }

  const query = useQuery()

  useEffect(() => {
    const queryResult: string | null = query.get("authData")
    if (queryResult != null) {
      setLoadingAuthData(true)
      const { datiAutenticazione }: Omit<LoginResponsePayload, "sistemi"> = JSON.parse(queryResult)

      getLoginAccesso(datiAutenticazione.tokenAccesso, datiAutenticazione.tokenAutorizzazione)
        .then(async res => {
          const resSistemi = await graphqlClient(
            datiAutenticazione.tokenAccesso,
            "zic_cfg",
            res.idSistemaRegistrato,
            res.idUnitaProduttivaRegistrata
          ).query<ZicCfgSistemiAccesso>({
            query: GET_ZIC_CFG_SISTEMA_ACCESSO,
            variables: {
              cfgIdsistema: res.idSistemaRegistrato
            }
          })

          const sistema = resSistemi.data.allCfgSistemis.nodes.length > 0 ? resSistemi.data.allCfgSistemis.nodes[0] : undefined

          const resUnitaProduttive = await graphqlClient(
            datiAutenticazione.tokenAccesso,
            sistema?.descrizione,
            res.idSistemaRegistrato,
            res.idUnitaProduttivaRegistrata
          ).query<CfgUnitaProduttiveAccesso>({
            query: GET_CFG_UNITA_PRODUTTIVE_ACCESSO
          })

          const utente = getDecodedToken(datiAutenticazione.tokenAutorizzazione)

          if (sistema && utente) {
            const resLocalizzazioni = await graphqlClient(
              datiAutenticazione.tokenAccesso,
              sistema.descrizione,
              res.idSistemaRegistrato,
              res.idUnitaProduttivaRegistrata
            ).query<LocalizzazioniDatiAll>({
              query: GET_LOCALIZZAZIONI_DATI_ALL,
              notifyOnNetworkStatusChange: true
            })
            const languageKeys: LanguageKey[] = resLocalizzazioni.data.allLocalizzazioniLocalizzazioniDatis.nodes.map(n => ({
              description: n.descrizione,
              localization: n.localizzazione
            }))
            languageSlice.addLanguageKey(languageKeys)

            axiosInstance("TSUITE", sistema.cfgIdsistema)
              .get<{ dati: string[]; interfaccia: string[] }>(`utenti/localizzazioni`, {
                headers: {
                  Authorization: `Bearer ${datiAutenticazione.tokenAccesso}`,
                  [X_TERYA_IDSISTEMA]: sistema.cfgIdsistema.toString()
                }
              })
              .then(res => {
                const activeLocalization = res.data.dati.length > 0 ? res.data.dati[0] : undefined

                languageSlice.selectLanguageKey(
                  activeLocalization ? activeLocalization : languageKeys.length > 0 ? languageKeys[0].localization : navigator.language
                )
              })

            const unitaProduttiva = res.idUnitaProduttivaRegistrata
              ? resUnitaProduttive.data.allCfgUnitaproduttives.nodes.find(u => Number(u.cfgIdunitaproduttiva) === Number(res.idUnitaProduttivaRegistrata))
              : undefined

            if (res.idSistemaRegistrato) {
              authContext.saveData({
                datiAutenticazione: datiAutenticazione,
                utente: utente.Utente,
                azienda: {
                  idSistema: res.idSistemaRegistrato,
                  idOrganizzazione: sistema.cfgOrganizzazioniByCfgIdorganizzazione.cfgIdorganizzazione,
                  nome: sistema.descrizione,
                  nomeOrganizzazione: sistema.cfgOrganizzazioniByCfgIdorganizzazione.descrizione,
                  unitaProduttive: resUnitaProduttive.data.allCfgUnitaproduttives.nodes.map(u => ({
                    idUnitaProduttiva: Number(u.cfgIdunitaproduttiva),
                    codice: u.codiceUnitaproduttiva,
                    nome: u.descrizione
                  }))
                },
                unitaProduttiva: unitaProduttiva
                  ? {
                      idUnitaProduttiva: Number(unitaProduttiva.cfgIdunitaproduttiva),
                      codice: unitaProduttiva.codiceUnitaproduttiva,
                      nome: unitaProduttiva.descrizione
                    }
                  : undefined,
                aziende: res.sistemi
              })
            } else {
              window.alert("Non è stata trovata alcuna azienda associata a questo utente, è necessario effettuare il login.")
              window.location.href = `https://login${getEnvironmentString()}.tsuite.cloud`
              localStorage.removeItem(`${APP_NAME}_${AUTH_DATA}`)
            }
          }
        })
        .then(_ => {
          query.delete("authData")
          history.replace({
            search: query.toString()
          })
        })
        .catch(err => {
          window.alert("Si è verificato un errore durante la connessione al server. Riprovare fra qualche minuto.")
          window.location.href = `https://login${getEnvironmentString()}.tsuite.cloud`
          localStorage.removeItem(`${APP_NAME}_${AUTH_DATA}`)
        })
        .finally(() => setLoadingAuthData(false))
    }
  }, [query])

  function getActiveClassParent(modulo: Modulo) {
    const routes = [modulo.route, ...modulo.children.map(m => m.route)]
    return routes.includes(activeRoute) ? styles.activeNavigationMenuItem : ""
  }

  const navigationMenu = (
    <List component="nav">
      {moduli
        .filter(m => m.enabled)
        .map(modulo => (
          <React.Fragment key={modulo.route}>
            <ListItem
              button={!modulo.headerOnly as any}
              className={clsx(styles.navigationMenuItem, getActiveClassParent(modulo))}
              onClick={_ => (modulo.headerOnly ? setCollapseState({ [modulo.route]: !collapseState[modulo.route] }) : changeRoute(modulo))}
            >
              <ListItemText primary={modulo.modulo} />
              {modulo.children.filter(m => m.enabled).length !== 0 ? (
                collapseState[modulo.route] ? (
                  <IconButton size="small">
                    <ExpandLessRoundedIcon
                      onClick={e => {
                        e.stopPropagation()
                        setCollapseState({ [modulo.route]: false })
                      }}
                      className={commonStyles.iconColor}
                    />
                  </IconButton>
                ) : (
                  <IconButton size="small">
                    <ExpandMoreRoundedIcon
                      onClick={e => {
                        e.stopPropagation()
                        setCollapseState({ [modulo.route]: true })
                      }}
                      className={commonStyles.iconColor}
                    />
                  </IconButton>
                )
              ) : (
                <></>
              )}
            </ListItem>

            {modulo.children.length !== 0 && (
              <Collapse in={collapseState[modulo.route]} unmountOnExit>
                <List component="div">
                  {modulo.children
                    .filter(m => m.enabled)
                    .map(moduloChild => (
                      <ListItem
                        key={moduloChild.route}
                        button
                        className={clsx(styles.navigationMenuNestedItem, activeRoute === moduloChild.route && styles.activeNavigationMenuNestedItem)}
                        onClick={_ => changeRoute(moduloChild)}
                      >
                        <ListItemText primary={moduloChild.modulo} />
                      </ListItem>
                    ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
    </List>
  )

  return (
    <React.Fragment>
      <Topbar withRibbon={false} onClickLogout={onClickLogout} authState={authContext.authenticationState} loading={loadingAuthData} />

      <AppDrawer withRibbon={false}>{navigationMenu}</AppDrawer>

      <ContentContainer withRibbon={false}>{loadingAuthData ? <FallbackComponent /> : props.children}</ContentContainer>

      <StatusBar authState={authContext.authenticationState} appVersion={packageJson.version} loading={loadingAuthData} withSelectionSistema />
    </React.Fragment>
  )
}

export default Skeleton
