import { ApolloProvider } from "@apollo/client"
import { StyledEngineProvider, Theme, ThemeProvider } from "@mui/material/styles"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import {
  AuthenticationProvider,
  ComposedContextProvider,
  Dashboard,
  ExternalLogin,
  FallbackComponent,
  FormPasswordDimenticata,
  Login,
  PrivateRoute,
  TabellaOperazioniProgrammate,
  localeEl,
  localeEn,
  localeEs,
  localeFr,
  localeIt,
  localePt,
  newGraphqlClient,
  serviceTheme
} from "@terya/tsuite-common"
import { TolgeeProvider } from "@tolgee/react"
import "ag-grid-enterprise"
import "cronstrue/locales/it"
import itLocale from "date-fns/locale/it"
import React from "react"
import ReactDOM from "react-dom"
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom"
import "./index.global.scss"
import Skeleton from "./skeleton/skeleton"

declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {}
}

const HOC: React.FC = () => {
  return (
    <TolgeeProvider
      staticData={{
        el: localeEl,
        en: localeEn,
        es: localeEs,
        fr: localeFr,
        it: localeIt,
        pt: localePt
      }}
      defaultLanguage="it"
      fallbackLanguage="en"
      // apiUrl={process.env.REACT_APP_TOLGEE_API_URL}
      // apiKey={process.env.REACT_APP_TOLGEE_API_KEY}
      // ui={process.env.REACT_APP_TOLGEE_API_KEY ? UI : undefined}
      highlightKeys={[2]}
      loadingFallback={
        <div style={{ height: "100vh" }}>
          <FallbackComponent />
        </div>
      }
    >
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={serviceTheme}>
          <Router>
            <Switch>
              {/* Sezione di login da abilitare solo in localhost */}
              {window.location.href.includes("://localhost:") && (
                <Route exact path="/">
                  <Redirect to="/login" />
                </Route>
              )}
              {window.location.href.includes("://localhost:") && <Route path="/login/resetpassword" component={FormPasswordDimenticata} />}
              {window.location.href.includes("://localhost:") && <Route path="/login" component={Login} />}

              <Route>
                <Skeleton>
                  <Switch>
                    <Route path="/login" children={<ExternalLogin />} />
                    <Route path="/dashboard" component={Dashboard} />

                    <PrivateRoute
                      path="/operazioni-programmate"
                      children={
                        <TabellaOperazioniProgrammate visualizzaPulsanteCrea={false} visualizzaPulsanteModifica={false} visualizzaPulsanteElimina={false} />
                      }
                    />

                    <Route path="*" children={<span>Pagina non trovata</span>} />
                  </Switch>
                </Skeleton>
              </Route>
            </Switch>
          </Router>
        </ThemeProvider>
      </StyledEngineProvider>
    </TolgeeProvider>
  )
}

const App: React.FC = () => {
  return (
    <ComposedContextProvider providers={[AuthenticationProvider]}>
      <LocalizationProvider adapterLocale={itLocale} dateAdapter={AdapterDateFns}>
        <ApolloProvider client={newGraphqlClient}>
          <HOC />
        </ApolloProvider>
      </LocalizationProvider>
    </ComposedContextProvider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
)
